import React, {useEffect, useState} from "react"
import {Form} from "react-final-form";
import {apiPutDeleteRegistrationImage, apiPostUploadPhoto, apiRegisterUser, apiGetPhoto} from "./Actions";
import {Button} from "../../components/My/Button";
import {Photo} from "./Photo";
import {Link, useNavigate} from "react-router-dom";
import CenteredForm from "../../layouts/centered-form";
import Alert from "../../components/alerts";
import Layout from "../../layouts/centered";
import {PersonalInfo} from "./PersonalInfo";
import {ExponatInfo} from "./ExponatInfo";
import {ActionDetail} from "./ActionDetail";
import Toast from "cogo-toast";
import * as Sentry from "@sentry/react";

const Registration = () => {

    let [loading, setLoading] = useState(false)
    let [registred, setRegistred] = useState(false);
    let [error, setError] = useState(null);

    let [photo,setPhoto] = useState([]);
    let active = true;
    let submitError = null;



    useEffect(() => {
        apiGetPhoto((data)=>{
            setPhoto(data)
        },(err)=>{
            setPhoto([]);
        })
    }, []);

    const onSubmit = (values) => {
        setLoading(true);
        values.photo = [];
        if (photo.length>0) {
            photo.forEach((item,index)=>{
                values.photo.push({id:item.id,name:item.name})
            })
            apiRegisterUser(values, (data) => {
                setRegistred(true);
                setLoading(false);
                setError(null);
            }, err => {
                Sentry.captureException(err);
                let msg = "";
                switch (err?.type) {
                    case "EMAIL_EXIST":
                        msg = "Zadaný email již je registrovaný.";
                        break;
                    default:
                        msg = "Při registraci došlo k chybě.";
                        break;
                }
                Toast.error(msg);
                setRegistred(false);
                setLoading(false);
            })
        } else {
            Toast.error("Vyberte alespoň jednu fotografii.")
            setLoading(false);
        }
    }

    if (!active) {
        return (
            <div className="w-full flex justify-center bg-grey-50">
                {/*<div className={"lg:fixed lg:top-0 lg:left-0 p-2"}> <Button link={"/"} text={"ZPĚT na úvodní stránku"} icon={<BiArrowBack/>}/></div>*/}
                <div className={"flex flex-col w-full max-w-5xl"}>

                    <div className={"flex items-center justify-center pt-4 pb-6"}>
                        <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                    </div>
                    <h2 className={"text-center font-bold mb-2"}>REGISTRACE</h2>
                    <div className={"w-full flex justify-center"}>
                        <h2>Registrace na Legendy 2025 ještě není spuštěna.</h2>
                    </div>
                </div>
            </div>
        )
    } else return (

        <div className="w-full flex justify-center bg-grey-50">
            {/*<div className={"lg:fixed lg:top-0 lg:left-0 p-2"}> <Button link={"/"} text={"ZPĚT na úvodní stránku"} icon={<BiArrowBack/>}/></div>*/}
            <div className={"flex flex-col w-full max-w-5xl"}>
                <div className={"flex items-center justify-center pt-4 pb-6"}>
                    <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                </div>

                {!registred ?
                    <Form onSubmit={onSubmit}
                          render={({handleSubmit, values, hasSubmitErrors,hasValidationErrors,...props}) => {
                              submitError = hasSubmitErrors || hasValidationErrors;
                              return (
                                  <div className={"flex flex-col"}>
                                      <PersonalInfo title={<h4 className="font-bold"><span
                                          className={"text-legendyColor"}>KROK 1</span> - VYSTAVOVATEL</h4>}
                                                    values={values}/>
                                      <ExponatInfo title={<h4 className="font-bold"><span
                                          className={"text-legendyColor"}>KROK 2</span> - EXPONÁT</h4>}/>
                                      <Photo data={photo} setData={setPhoto} title={<h4 className="font-bold"><span className={"text-legendyColor"}>KROK 3</span> -
                                          FOTOGRAFIE</h4>}/>
                                      <ActionDetail title={<h4 className="font-bold"><span
                                          className={"text-legendyColor"}>KROK 4</span> - DETAIL</h4>}
                                                    values={values} errors={props.errors}
                                                    submitFailed={props.submitFailed}/>
                                      {submitError ?
                                          <span className={"text-red-500"}>Vyplntě prosím všechna povinná pole.</span> : <></>}
                                      <div className={"flex flex-1/3 mx-auto mb-4"}>
                                          <Button onClick={handleSubmit} text={"Dokončit registraci"} loading={loading}
                                                  textSize={"text-lg"}/>
                                      </div>
                                      <div className={"flex flex-1/3 mx-auto mb-4"}>
                                          <p>V případě problémů s registrací nás neváhejte kontaktovat a obratem se na
                                              nás obraťte:
                                              info@legendy.cz< br/>
                                              Napište na sebe kontakt: Jméno, telefon - mobil, značku vozu, který chcete
                                              registrovat.
                                              My se Vám ozveme a pomůžeme s registrací.</p>
                                      </div>
                                  </div>
                              )
                          }}
                    /> :
                    <Layout>
                        <div className={"flex flex-col w-full max-w-xl"}>
                            <div className={"flex items-center justify-center pb-6"}>
                                <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                            </div>
                            <CenteredForm subtitle="Potvrzení registrace" title={""}>
                                <Alert color={"text-green-500"} outlined={true}>Registrace byla úspěšná, potvrďte ji
                                    pomocí odkazu ve Vašem emailu.</Alert>

                            </CenteredForm>
                            <div className={"justify-center w-full text-center pt-2"}>
                                <Link to={"/login"} className={"text-legendyColor text-base"}>Přihlásit se</Link>
                            </div>
                        </div>
                    </Layout>
                }

            </div>
        </div>
    )
}

export default Registration
