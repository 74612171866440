import InputField from "../../components/FinalForm/InputField";
import React from "react";
import RadioField from "../../components/FinalForm/RadioField";
import {SectionSelectField} from "../Section/SectionSelectField";


export const ExponatInfo = ({title,type="register"}) => {
    return (
        <div className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full ${type==="register"?'lg:max-w-5xl':''} mb-2 text-base`}>
            <div className="flex flex-col w-full mb-1">
                {title}
            </div>
            <div className="flex flex-col w-full mb-16">
                <div className="text-lg">Detailně popište svůj exponát, který přihlašujete k účasti.</div>
            </div>
            <div className="form flex flex-wrap w-full">
                <div className="lg:w-1/3 sm:w-1/2 w-full mb-4">
                    <div className={"h-32 align-middle"}>
                        <label htmlFor={"cc-t" + 0} className={"cursor-pointer"}>
                            <div style={{
                                backgroundImage: `url(/assets/automobil.png)`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "center"
                            }} className={"lg:h-32 h-32 rounded p-2"}>

                            </div>
                        </label>
                    </div>
                    <div className={"w-1/3 mx-auto text-legendyColor text-lg font-bold"}>
                    <RadioField name={"exponat.type"} label={"AUTOMOBIL"} id={"cc-t" + 0} value={0}
                                group={"exponat.type"} isMandatory={true}/>
                    </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full mb-4">
                    <div className={"h-32 align-middle"}>
                    <label htmlFor={"cc-t" + 1} className={"cursor-pointer"}>
                        <div style={{
                            backgroundImage: `url(/assets/motocykl.png)`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center"
                        }} className={"max-h lg:h-32 h-32 rounded p-2"}>
                        </div>
                    </label>
                    </div>
                    <div className={"w-1/3 mx-auto text-legendyColor text-lg font-bold"}>
                        <RadioField name={"exponat.type"} label={"MOTOCYKL"} id={"cc-t" + 1} value={1}
                                    group={"exponat.type"} isMandatory={true}/>
                    </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full mb-4">
                    <div className={"h-32 align-middle"}>
                    <label htmlFor={"cc-t" + 2} className={"cursor-pointer"}>
                        <div style={{
                            backgroundImage: `url(/assets/karavan.png)`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center"
                        }} className={"max-h lg:h-32 h-32 rounded p-2"}>

                        </div>
                    </label>
                    </div>
                    <div className={"w-1/3 mx-auto text-legendyColor text-lg font-bold"}>
                        <RadioField name={"exponat.type"} label={"KARAVAN"} id={"cc-t" + 2} value={2}
                                    group={"exponat.type"} isMandatory={true}/>
                    </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full mb-4">
                    <div className={"h-32 align-middle"}>
                        <label htmlFor={"cc-t" + 3} className={"cursor-pointer"}>
                            <div style={{
                                backgroundImage: `url(/assets/nakladni-vozidlo.png)`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "center"
                            }} className={"max-h lg:h-32 h-32 rounded p-2"}>

                            </div>
                        </label>
                    </div>
                    <div className={"w-1/3 mx-auto text-legendyColor text-lg font-bold"}>
                        <RadioField name={"exponat.type"} label={"NÁKLADNÍ"} id={"cc-t" + 3} value={3}
                                    group={"exponat.type"} isMandatory={true}/>
                    </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full mb-4">
                    <div className={"h-32 align-middle"}>
                    <label htmlFor={"cc-t" + 4} className={"cursor-pointer"}>
                        <div style={{
                            backgroundImage: `url(/assets/jine.png)`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center"
                        }} className={"max-h lg:h-32 h-32 rounded p-2"}>

                        </div>
                    </label>
                    </div>
                    <div className={"flex items-center justify-center text-legendyColor text-lg font-bold"}>
                        <RadioField name={"exponat.type"} label={"JINÉ"} id={"cc-t" + 4} value={4}
                                    group={"exponat.type"} isMandatory={true}/>
                    </div>
                </div>


                <div className="w-full mt-16">
                    <InputField name={"exponat.nameExponat"} label={"Název exponátu"} inputType={"text"}
                                placeholder={"Zadejte název exponátu"} isMandatory={true} maxLength={300}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.manufactureYear"} label={"Rok výroby"} inputType={"number"}
                                placeholder={"Zadejte"} isMandatory={true} maxLength={4}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.engine"} label={"Motor (počet válců)"} inputType={"text"}
                                placeholder={"Zadejte"} isMandatory={true} maxLength={10}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.engineCapacity"} label={"Objem motoru (ccm)"} inputType={"text"}
                                placeholder={"Zadejte"} isMandatory={true} maxLength={10}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.performanceHp"} label={"Výkon motoru v HP (koně)"} inputType={"text"}
                                placeholder={"Zadejte"} isMandatory={true} maxLength={10}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.performanceKw"} label={"Výkon v kW"} inputType={"text"}
                                placeholder={"Zadejte"} isMandatory={true} maxLength={10}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.maxSpeed"} label={"Max. rychlost (km/h)"} inputType={"text"}
                                placeholder={"Zadejte"} isMandatory={true} maxLength={10}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.consumption"} label={"Spotřeba (l/100km)"} inputType={"text"}
                                placeholder={"Zadejte"} isMandatory={true} maxLength={10}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.value"} label={"Hodnota"} inputType={"text"}
                                placeholder={"Zadejte"} isMandatory={false} maxLength={20}/>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 w-full">
                    <InputField name={"exponat.exhibited"} label={"Laskavě vystavil (vaše jméno)"} inputType={"text"}
                                placeholder={"Zadejte"} isMandatory={false} maxLength={100}/>
                </div>

                <div className="w-full">
                    <InputField name={"exponat.note"} label={"Zajímavost o voze (350 znaků)"} inputType={"textarea"}
                                placeholder={"Zadejte"} isMandatory={true} maxLength={400}/>
                </div>
                <SectionSelectField name={"exponat.section"} isMandatory={true} label={"Sekce, do které bych zařadil svůj exponát"} isMulti={false} type={"register"}/>
                {type==="register"&&
                <div className="w-full text-center">
                   Pokud chcete přihásit více exponátů, tak vyčkejte na první přihlášení. <br/>Následně ve svém účtu můžete přidávat nebo odebírat své exponáty.
                </div>
                }
            </div>
        </div>
    )
}
