import InputField from "../../../components/FinalForm/InputField";
import EmailField from "../../../components/FinalForm/EmailField";
import React from "react";
import PhoneField from "../../../components/FinalForm/PhoneField";
import TelephoneField from "../../../components/FinalForm/TelephoneField";

const UserInfoForm = ({data, title="VYSTAVOVATEL", part = 3,disabled=false}) => {
    return (
        <div className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
            <div className="flex flex-row w-full mb-6">
                <h4 className="font-bold"><span className={"text-legendyColor"}>ČÁST {part}</span> - {title}</h4>
            </div>
            <div className={"grid lg:grid-cols-4 grid-cols-1 gap-2 mb-2"}>
                <InputField disabled={disabled} name={'user.firstname'} label={"Jméno"}
                            inputType={"text"}
                            placeholder={"Zadejte jméno"} isMandatory={true} maxLength={200}/>
                <InputField disabled={disabled} name={'user.surname'} label={"Příjmení"}
                            inputType={"text"}
                            placeholder={"Zadejte příjmení"} isMandatory={true} maxLength={200}/>
                <InputField disabled={disabled} name={"user.age"} label={"Věk"} inputType={"number"}
                            placeholder={"Zadejte věk"} isMandatory={true} maxLength={3}/>
                <TelephoneField name={"user.phone"} label={"Telefon"} isMandatory={true}/>
                {/*<InputField disabled={disabled} name={"user.phone"} label={"Telefon"} inputType={"text"}
                            placeholder={"Zadejte telefon"} isMandatory={true} maxLength={30}/>*/}
                <InputField disabled={disabled} name={"user.street"} label={"Ulice, číslo popisné"} inputType={"text"}
                            placeholder={"Zadejte ulici"} isMandatory={true} maxLength={250}/>
                <EmailField disabled={disabled} name={"user.email"}
                            label={"E-mail"}
                            placeholder={"Zadejte email"} disabled={true}
                            isMandatory={true} maxLength={200}/>
                <InputField disabled={disabled} name={"user.city"} label={"Město"} inputType={"text"}
                            placeholder={"Zadejte město"} isMandatory={true} maxLength={250}/>
                <InputField disabled={disabled} name={"user.psc"} label={"PSČ"} inputType={"text"}
                            placeholder={"Zadejte PSČ"} isMandatory={true} maxLength={250}/>
            </div>
        </div>
    )
}
export default UserInfoForm;
