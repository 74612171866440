export const USER_SET_TOKEN = "USER_SET_TOKEN";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_RESET = "USER_RESET";
export const FETCH_USER_START = "FETCH_USER_START";
export const SET_ACCEPT_TERMS = "SET_ACCEPT_TERMS";

export default function reducer(
    state = {
        user: null,
        token: null,
        acceptTerms: false
    },
    action
) {
    switch (action.type) {
        case FETCH_USER_START: {
            return { ...state, fetching: true, fetched: false, error: null }
        }
        case USER_SET_TOKEN: {
            return {
                ...state,
                token: action.payload
            }
        }
        case USER_FETCH_SUCCESS: {
            let user = action.payload;
            let acceptTerms = false;
            if(action.payload.data){
                user = {
                    firstname: action.payload.firstname,
                    surname: action.payload.surname,
                    user_type: action.payload.user_type,
                }
                acceptTerms = action.payload.data.acceptTerms;
            }
            return {
                ...state,
                user: user,
                acceptTerms: acceptTerms,
            }
        }
        case SET_ACCEPT_TERMS:{
            return {
                ...state,
                acceptTerms: action.payload
            }
        }
        case "RESET":
        case USER_RESET: {
            return {
                ...state,
                user: null,
                token: null,
                acceptTerms: false
            }
        }
        default:
            return state

    }
}

