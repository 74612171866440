import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import {instance} from "./config/axios-cnf";
import {getTokenFromLocalStorage} from "./pages/LoginPage/Actions";
import Routing from "./Routing";
import Auth from "./pages/LoginPage/Auth";
import {USER_RESET} from "./reducers/user";
import Toast from "cogo-toast";
import Layouts from "./layouts";

import './css/tailwind.css'
import './css/animate.css'
import './css/main.css'
import './css/_components.css'
import LoginRouter from "./pages/LoginPage/LoginRouter";
import AcceptTermsModal from "./AcceptTermsModal";


const Token = () => {
    let [done, setDone] = useState(false);


    const {user} = useSelector((state) => state.user);
    let token = localStorage.getItem("authorization-leg-enigoo");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getTokenFromLocalStorage(dispatch);
        setDone(true);
        setErrorHandler();
    }, []);

    const setErrorHandler = () => {
        instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.response?.status === 403) {
                    Toast.success("Přihlašte se prosím znovu.");
                    dispatch({type: USER_RESET});
                    localStorage.removeItem("authorization-leg-enigoo");
                    instance.defaults.headers.common["Authorization"] = null;
                    navigate("/login");
                } else {
                    return Promise.reject(error);
                }
            }
        );
        instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.response?.status === 401 && !window.location.pathname.includes("/create-account")) {
                    Toast.info("Nejste autorizován. Přihlašte se prosím znovu.");
                    dispatch({type: USER_RESET});
                    localStorage.removeItem("authorization-leg-enigoo");
                    instance.defaults.headers.common["Authorization"] = null;
                    navigate("/login");
                } else {
                    return Promise.reject(error);
                }
            }
        );
    };

    if (!done) return null;

    if (!token) return <LoginRouter/>;
    if (!user) return <Auth/>;

    return (
        <Layouts>
            <AcceptTermsModal/>
            <Routing/>
        </Layouts>
    );
};

export default Token;
