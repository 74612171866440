import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Layout from '../../layouts/centered'
import CenteredForm from '../../layouts/centered-form'
import {Form} from "react-final-form";
import InputField from "../../components/FinalForm/InputField";
import {apiLoginUser, axiosSetAuthorizationToken} from "./Actions";
import {useDispatch} from "react-redux";
import Alert from "../../components/alerts";
import {Button} from "../../components/My/Button";
import EmailField from "../../components/FinalForm/EmailField";
import {SET_ACCEPT_TERMS} from "../../reducers/user";


const LoginPage = () => {

    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(null)
    let dispatch = useDispatch()


    const onLogin = (values) => {
        setError(null)
        setLoading(true)
        apiLoginUser(values, (data) => {
            setLoading(false)
            axiosSetAuthorizationToken(data.token, dispatch);
            dispatch({type:SET_ACCEPT_TERMS, payload: data.data.acceptTerms})
        }, error => {
            setLoading(false);
            switch (error.type) {
                case "LOGIN_ERROR":
                    setError("Špatné přihlašovací údaje.");
                    break;
                case "USER_NOT_VERIFED":
                    setError("Účet nebyl dosud verifikován. Klikněte na odkaz v e-mailu.");
                    break;
                default:
                    setError("Došlo k chybě")
            }
        })
    }

    return (
        <Layout>
            <div className={"flex flex-col w-full max-w-xl"}>
                <div className={"flex items-center justify-center pb-6"}>
                    <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                </div>
                <CenteredForm subtitle="Uživatelská sekce">
                    {error && <Alert color={"text-red-500"} outlined={true}>{error}</Alert>}
                    <Form onSubmit={onLogin}
                          render={({handleSubmit}) => (
                              <form className="form flex flex-wrap w-full">
                                  <div className="w-full">
                                      <EmailField name={"login"} label={"Přihlašovací e-mail"}
                                                  placeholder={"Přihlašovací e-mail"} isMandatory={true}
                                                  onEnter={() => handleSubmit()}/>
                                  </div>
                                  <div className="w-full">
                                      <InputField name={"password"} inputType={"password"} label={"Heslo"}
                                                  isMandatory={true} onEnter={() => handleSubmit()}/>
                                  </div>
                                  <Button text={"Přihlásit se"} onClick={handleSubmit} loading={loading}/>
                              </form>
                          )}
                    />


                    <div className="flex flex-row w-full mt-3">
                        <span className="text-secondary mr-1">Nový uživatel?</span>
                        <span className={"text-legendyColor"}>
                            <Link className="link" to="/create-account">
                                <span className={"text-legendyColor"}>Vytvořte si svůj účet</span>
                            </Link>
                        </span>
                    </div>
                    <div className="w-full">
                      <span>
                        <Link className="link" to="/forgot-password">
                          <span className={"text-legendyColor"}>Zapomenuté heslo?</span>
                        </Link>
                      </span>
                    </div>

                </CenteredForm>
            </div>
        </Layout>
    )
}

export default LoginPage
