import React, {useState} from "react";
import FileField from "../../components/FinalForm/FileField";
import {ImageGallery} from "../../components/image-modal/ImageGallery";
import {Button} from "../../components/My/Button";
import {FiPlus} from "react-icons/fi";
import PhotoModal from "../Exponat/Form/PhotoModal";
import {apiPostUploadPhoto, apiPutDeleteRegistrationImage} from "./Actions";
import Toast from "cogo-toast";

export const Photo = ({
                          title,
                          type = "register",
                          data,
                          setData
                      }) => {
    let [showModal, setShowModal] = useState(false);

    let images = [
        {src: "https://legendy-registrace.enigoo.cz/car_photo_1.jpeg", title: "Interiér"},
        {src: "https://legendy-registrace.enigoo.cz/car_photo_2.jpeg", title: "Z boku"},
        {src: "https://legendy-registrace.enigoo.cz/car_photo_3.jpeg", title: "Ze zadu"},
        {src: "https://legendy-registrace.enigoo.cz/car_photo_4.jpeg", title: "Ze předu"},
    ]
    return (
        <div
            className={`flex flex-col bg-white border border-grey-200 lg:p-8 sm: p-2 w-full ${type === "register" ? 'lg:max-w-5xl' : ''} sm:max-w-full mb-2 text-base`}>
            <div className="flex flex-col w-full">
                {title}
                <div>(Jedna fotografie o maximální velikosti <b>5 MB</b> a ve formátu <b>.jpg</b>)</div>
                <div className={"mt-1"}>Pro rychlé schválení vašeho exponátu nahrajte 4 kvalitní fotografie - exponát je
                    ostrý a je v celé velikosti fotografie, ideálně vyfoťme bez rušivých elementů (na polní cestě, na
                    prázdném parkovišti, na cestě za obcí), jasná detailní fotografie. Děkujeme.
                </div>

            </div>

            <div className={"mt-4 mb-2 font-bold uppercase"}>Vzorové fotografie:</div>
            <ImageGallery images={images} showTitle={true}/>

            {data && data.length<5 && <Button onClick={() => setShowModal(true)} text={"Přidat fotografii"} icon={<FiPlus/>}/>}
            <PhotoModal apiUploadImage={apiPostUploadPhoto} setOpen={setShowModal} open={showModal}
                        addPhoto={(photo) => {
                            if (setData) {
                                let images = data;
                                images.push({src: photo.data, name: photo.name, id: photo.id})
                                setData(images);
                            }
                        }}/>
            {data && data.length>0 ? <>
                <div className={"mr-2 font-bold uppercase mt-2"}>Fotografie exponátu:</div>
                <ImageGallery images={data ? data : []}
                              onDelete={(index) => {
                                  let photo = data[index];
                                  apiPutDeleteRegistrationImage({
                                      id: photo.id,
                                      name: photo.name
                                  }, (result) => {
                                      const newData = [...data.slice(0, index), ...data.slice(index + 1)];
                                      setData(newData)
                                      Toast.success("Obrázek byl odstraněn.")
                                  }, (error) => {
                                      Toast.error("Nepodařilo se odstranit obrázek.")
                                  })
                              }}
                /></> : <></>}
        </div>
    )
}
