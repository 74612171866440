import React, {useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {FiSend} from "react-icons/fi";
import {Form} from "react-final-form";
import {Button} from "./components/My/Button";
import Portal from "./components/portal";
import {apiPostAcceptTerms} from "./pages/User/Actions";
import {SET_ACCEPT_TERMS} from "./reducers/user";
import CogoToast from "cogo-toast";
import CheckboxField from "./components/FinalForm/CheckboxField";

const AcceptTermsModal = () => {
    const {user} = useSelector((state) => state);

    const dispatch = useDispatch();
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.palettes,
        }),
        shallowEqual
    )

    let {background} = {
        ...palettes
    }

    let [saving, setSaving] = useState(false);

    const modalRef = useRef(null)

    const send = (values) => {
        if(!values.accept){
            CogoToast.error("Musíte souhlasit s podmínkami.")
        }else{
            setSaving(true);
            apiPostAcceptTerms((data)=>{
                dispatch({type:SET_ACCEPT_TERMS, payload:true})
                setSaving(false);
            },(err)=>{
                CogoToast.error("Nepodařilo se zpracovat Váš požadavek")
                setSaving(false);
            })
        }
    }

    if (!user.acceptTerms) {
        return (
            <Portal selector="#portal">
                <div className="modal-backdrop fade-in bg-blue-500"></div>
                <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                     data-background={background}>
                    <div className="relative min-w-sm w-1/2 mx-auto lg:max-w-5xl bg-white rounded" ref={modalRef}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="text-xl font-semibold">{"Nové podmínky pro registraci"}</h3>
                            </div>
                            <Form onSubmit={send} render={({handleSubmit}) => (
                                <>
                                    <div className={"m-5 text-lg text-justify"}>
                                        <div className={"mb-2"}>
                                            Vážení účastníci,
                                        </div>
                                        <div className={"mb-2"}>
                                            rádi bychom Vás informovali o&nbsp;důležitých změnách týkajících
                                            se&nbsp;registrace
                                            vozů. Prosíme, věnujte této zprávě zvýšenou pozornost.
                                        </div>
                                        <div><strong>Pozor, změny v registraci vozu</strong></div>
                                        <div className={"mb-2"}>
                                            Pro letošní rok 2025 došlo k&nbsp;úpravám v&nbsp;procesu registrace vozidel,
                                            konkrétně k&nbsp;zavedení
                                            registračního poplatku. Tato změna byla provedena z&nbsp;důvodu nejen
                                            organizačních, ale také
                                            na&nbsp;základě odsouhlasení odpovědných sekčních vedoucích daných
                                            výstavních ploch. Ujistěte se, že
                                            jste obeznámeni s&nbsp;novými podmínkami, které mohou ovlivnit Vaši
                                            registraci.
                                        </div>
                                        <div><strong>Registrační poplatek</strong></div>
                                        <div className={"mb-2"}>
                                            Registrační poplatek činí 1.000,-Kč&nbsp;/&nbsp;osobu. Zahrnuje třídenní vstup na&nbsp;slavnost, občerstvení
                                            v&nbsp;zázemí Driver’s clubu po&nbsp;celou dobu konání akce.
                                        </div>
                                        <div><strong>Dbejte a dodržujte podmínky účasti a registrace</strong></div>
                                        <div className={"mb-2"}>
                                            Je nezbytné, abyste pečlivě přečetli a dodržovali všechny podmínky účasti a registrace. Jakékoliv
                                            dotazy či pomoc při svých registracích řešte se sekčním vedoucím, který má danou sekci na
                                            starost. Pokud nevíte, kdo je vedoucím dané sekce, kontaktujte nás na emailové adrese
                                            info@legendy.cz.
                                        </div>
                                        <div><strong>Souhlas s&nbsp;podmínkami registrace</strong></div>
                                        <div className={"mb-2"}>
                                            Odkliknutím a přihlášením do&nbsp;systému této registrace potvrzujete svůj souhlas s&nbsp;dodržením všech
                                            postupů a podmínek registrace a řádem slavnosti Legendy. Dbejte prosím na&nbsp;to, že nedodržení
                                            těchto podmínek může mít vliv na&nbsp;Vaši účast.
                                        </div>
                                        <div className={"mb-2"}>Děkujeme za&nbsp;pochopení a těšíme se na&nbsp;Vaši účast!</div>
                                        <div className={"w-full text-center font-bold"}>
                                            <CheckboxField name={"accept"} isMandatory={false} label={"Souhlasím s novými podmínkami"}/>
                                        </div>
                                    </div>

                                    <div className="modal-footer space-x-2">
                                        <Button disabled={saving} loading={saving} onClick={handleSubmit}
                                                text={"Odeslat"} icon={<FiSend/>}/>
                                    </div>
                                </>
                            )}/>
                        </div>
                    </div>
                </div>
            </Portal>
        )
    } else {
        return <></>
    }
}

export default AcceptTermsModal;
