import {instance, onErrorFunction} from "../../config/axios-cnf";

export const apiGetUser = (callback, error) => {
    instance.get("/api/v1/user")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetUserById = (id, callback, error) => {
    instance.get("/api/v1/user/" + id)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostCreateUser = (data, callback, error) => {
    instance.post("/api/v1/user", data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutUpdateUser = (id, data, callback, error) => {
    instance.put("/api/v1/user/" + id, data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetActualUserInfo = (callback,error) => {
    instance.get("/api/v1/user/info")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostAcceptTerms = (callback, error) => {
    instance.post("/api/v1/user/acceptTerms")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
