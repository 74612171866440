import React from "react";
import RadioField from "../../../components/FinalForm/RadioField";
import InputField from "../../../components/FinalForm/InputField";

const DriverClubForm = ({values,title="DRIVER'S CLUB", part=5, errors, submitFailed, disabled=false}) => {

    return (
        <div className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
            <div className="flex flex-row w-full mb-6">
                <h4 className="font-bold"><span className={"text-legendyColor"}>ČÁST {part}</span> - {title}</h4>
            </div>
            <div className={"w-full"}>
                <div className={"font-bold"}>DŮLEŽITÉ INFORMACE</div>
                <div>Driver's club je místo, které slouží jako zázemí pro majitelé vozů a jejich doprovod.</div>
                <div>Driver's club nabízí v průběhu akce po tři dny:</div>
                <ul>
                    <li> - 4x teplé menu dle aktuální nabídky</li>
                    <li> - pitný režizm</li>
                </ul>
                <div>Tento vstup je vystaven na jméno a je nepřenosný.</div>
                <div>Tento vstup je povolen pouze v případě, že bylo uvedeno celé jménoo účastníka.</div>
                <div>Na místě již není možnost využití, není-li součástí této registrace.</div>
                <div className={"w-full grid grid-cols-2 gap-2 mt-2"}>
                    <div className={"w-full"}>
                        <div className={"font-bold flex-col w-full"}>Přeji si mít přístup do DRIVER'S Clubu a
                            využívat jej: <sup className={"text-legendyColor"}>*</sup></div>

                        <div className="w-full flex flex-row">
                            <div className={"flex-col"}>
                                <RadioField name={"user.detail.driverClub.entry"} label={"ANO"}
                                            id={"driver-club-entry" + 0} value={1}
                                            group={"driverClub.entry"}
                                            defaultChecked={values && values?.user?.detail?.driverClub?.entry === 1}
                                            isMandatory={true}/>
                            </div>
                            <div className={"flex-col"}>
                                <RadioField name={"user.detail.driverClub.entry"} label={"NE"}
                                            id={"driver-club-entry" + 1} value={0}
                                            defaultChecked={values && values?.user?.detail?.driverClub?.entry === 0}
                                            group={"driverClub.entry"} isMandatory={true}/>
                            </div>
                        </div>
                    </div>
                    {values?.user?.detail?.driverClub?.entry === 1 && <div className={"w-full"}>
                        <InputField label={"Jméno a příjmení"} name={"user.detail.driverClub.name"} isMandatory={true}/>
                    </div>}
                    {values?.user?.detail?.driverClub?.entry === 1 && <div className={"w-full"}>
                        <div className={"font-bold flex-col w-full"}>Přeji si využít také druhý vstup do DRIVER'S
                            Clubu pro jeden doprovod: <sup className={"text-legendyColor"}>*</sup></div>

                        <div className="w-full flex flex-row">
                            <div className={"flex-col"}>
                                <RadioField name={"user.detail.driverClub.secondEntry"} label={"ANO"}
                                            id={"driver-club-second-entry" + 0} value={1}
                                            group={"driverClub.secondEntry"}
                                            defaultChecked={values && values?.user?.detail?.driverClub?.secondEntry === 1}
                                            isMandatory={true}/>
                            </div>
                            <div className={"flex-col"}>
                                <RadioField name={"user.detail.driverClub.secondEntry"} label={"ANO"}
                                            id={"driver-club-second-entry" + 1} value={0}
                                            group={"driverClub.secondEntry"}
                                            defaultChecked={values && values?.user?.detail?.driverClub?.secondEntry === 0}
                                            isMandatory={true}/>
                            </div>
                        </div>
                    </div>}
                    {values?.user?.detail?.driverClub?.entry === 0 && <div className={"w-1/2"}>
                        <InputField label={"Důvod"} name={"user.detail.driverClub.noEntryWhyNot"} isMandatory={false}/>
                    </div>}
                </div>
            </div>
        </div>
    )

}
export default DriverClubForm;
